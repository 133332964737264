/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { defer } from 'rsvp';
import EmberObject, { computed, observer } from '@ember/object';
import { inject, inject as service } from '@ember/service';
import { A } from '@ember/array';
import { alias } from '@ember/object/computed';
import moment from 'moment';
import {
	automationOptions,
	CustomFrequencyDefaults,
} from '../constants/automation-options';
import { capitalize } from '@ember/string';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { next } from '@ember/runloop';
import Errors from '../constants/errors';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Changeset from 'ember-changeset';

const weekdays = [0, 1, 2, 3, 4];
// keep this updated with automation types that require a stage/consecutive stages be selected
const typesRequiringStageValidation = [
	'no_case_changes',
	'recurring_message',
	'treatment_reminder',
	'add_checklist_template',
	'add_checklist_template_chat',
	'status_message',
	'nps_message',
];

@classic
export default class AutomationItem extends Component {
	@inject('custom-branding') customBranding;
	@service company;
	@service currentUser;
	@service store;
	@service notifications;
	@service shortcode;
	@service permissions;
	@service global;
	@alias('automation.daysAfterEvent') daysAfterEvent;
	@alias('automation.callLimit') limitCount;

	@tracked showFrequencyIntervalModal = false;
	@tracked savedCustomRecurrenceData = null;

	get selectedLimitType() {
		if (this.limitCount) {
			return this.limitTypeOptions[1];
		} else {
			return this.limitTypeOptions[0];
		}
	}

	set selectedLimitType(value) {
		switch (value) {
			case this.limitTypeOptions[0]:
				this.set('limitCount', null);
				break;
			case this.limitTypeOptions[1]:
				if (this.rawAutomation?.callLimit) {
					this.set('limitCount', this.rawAutomation.callLimit);
				} else {
					this.set('limitCount', this.minLimitCount);
				}
				break;
		}
	}

	get isEditing() {
		return this._isEditing;
	}

	set isEditing(value) {
		this.set('global.casePauseTimerConditions.isEditingAutomation', value);
		this.set('_isEditing', value);
	}

	get minLimitCount() {
		//* Use a getter function to prevent setting this as it is a strict static value
		return this.automation.get('minCallLimit') || 1;
	}

	limitTypeOptions = A(['None', 'Only send message']);

	get showLimitWarning() {
		return (
			this.selectedLimitType == this.limitTypeOptions[1] &&
			this.limitCount < this.minLimitCount
		);
	}

	get isChecklistTemplateType() {
		return (
			this?.automation?.get('automationType') == 'add_checklist_template' ||
			this.automation?.get('automationType') == 'add_checklist_template_chat'
		);
	}
	get areSelectedStagesConsecutive() {
		if (!this.caseType) return true;
		if (!this.automation.get('stageValidationRequired')) return true;
		let stages = this?.automation?.get('statuses') || [];
		if (stages.length === 1) return true;
		if (stages.length === 0) return false;
		//* Sort the items
		stages = stages.sort((a, b) => a - b);

		//* loop through checking if the stage is equal to the previous plus one, if yes continue, if not for even one break and return false.
		for (let i = 1; i < stages.length; i++) {
			const currentStage = Number(stages[i]);
			const previousStage = Number(stages[i - 1]);
			if (isNaN(currentStage)) throw Error(`Stage item (${i}) is NaN!`); //* This shouldn't happen
			if (isNaN(previousStage)) throw Error(`Stage item (${i - 1}) is NaN!`); //* This shouldn't happen
			if (previousStage + 1 === currentStage) {
				continue;
			} else {
				return false;
			}
		}

		//* Returns true if all checks pass
		return true;
	}

	get isAutomationValid() {
		if (!this.automation.get('content')) return false;
		if (!this.automation.get('automationType')) return false;
		if (
			this.isChecklistTemplateType &&
			(!this.automation.get('checklistTemplate') ||
				!this.automation.get('statuses.length'))
		)
			return false;
		if (!this.areSelectedStagesConsecutive) return false;
		if (
			this.automation.get('automationType') == 'nps_message' &&
			!(this.automation.get('npsScores.length') > 0)
		)
			return false;
		if (
			this.automation.get('automationType') == 'recurring_message' &&
			this.checkIntervalLabel == 'Weekly' &&
			this.automation.get('daysOfWeek.length') < 1
		)
			return false;

		return true;
	}

	get isStatusChangeAutomationType() {
		return ['status_message', 'delayed_status_message'].includes(
			this.automation.get('automationType'),
		);
	}

	get shouldShowLimit() {
		// these should be excluded from showing the limit step
		const excluded = [
			'add_checklist_template',
			'status_message',
			'delayed_status_message',
			'date_based',
			'nps_message',
		];
		return !excluded.includes(this.automation.get('automationType'));
	}

	get shouldShowTimeSelector() {
		// these should be excluded from showing the time selector
		const excluded = [
			'status_message',
			'delayed_status_message',
			'nps_message',
		];
		return !excluded.includes(this.automation.get('automationType'));
	}

	get shouldShowDuringStageStep() {
		// these should be excluded from showing the 'during stage' selector
		const excluded = [
			'status_message',
			'delayed_status_message',
			'nps_message',
			'date_based',
		];
		return !excluded.includes(this.automation.get('automationType'));
	}

	resetStages() {
		// reset the statuses on which the automation will run
		this.automation.set('statuses', []);
		// reset the selected statuses in the GUI so the selections don't carry over from a previously selected automation template type
		this.stages.forEach((stage) => {
			stage.set('checked', false);
		});
	}

	init() {
		super.init(...arguments);

		this.set('rawAutomation', this.automation);

		if (this.automation && this.isEditing) {
			this.set('automation', new Changeset(this.automation));
		}

		if (this.isChecklistTemplateType && this.isEditing) {
			this.automation.content = ' ';
		}

		let modelType = 'automation';
		if (this.caseType) {
			modelType = 'automation-template';
			this.set(
				'activeMessageTemplate',
				this.automation?.get('messageTemplate'),
			);
		}

		this.set(
			'triggerOptions',
			automationOptions.triggerOptions.map((option) => {
				return `${option}`.replace(
					'Case',
					capitalize(dynamicCaseLabel([this.company])),
				);
			}),
		);

		if (this.isAutomationTemplate) {
			this.triggerOptions.push('Date Based Message');

			if (this.currentUser.permissions.checklist) {
				this.triggerOptions.push('Add Checklist Template');
				this.triggerOptions.push('Add Internal Checklist Template');
			}

			this.triggerOptions.push('Single Message');
			this.triggerOptions.push('Single Delayed Message');
			this.triggerOptions.push('Single Message based on NPS');
		}

		this.set('triggerTypeOptions', automationOptions.triggerTypeOptions);
		this.set('monthDayOptions', automationOptions.monthDayOptions);
		this.set('hourOptions', automationOptions.hourOptions);
		this.set(
			'weekDayOptions',
			automationOptions.weekDayOptions.map((day) =>
				EmberObject.create({ ...day, checked: this.isNew && day.value < 5 }),
			),
		);

		if (this.isNew) {
			if (this.caseType) {
				this.stages = this.caseType?.caseStatuses
					?.sortBy('number')
					.map((status) => {
						return EmberObject.create({
							value: status.number,
							checked: true,
							label: status.name,
						});
					});
			}

			//* Set defaults for new automation
			//* Default all stages to true in new automationTemplates
			const defaults = this.caseType
				? {
						statuses: this.caseType?.caseStatuses
							?.sortBy('number')
							.map((status) => {
								return status.number;
							}),
				  }
				: {};

			this.set('automation', this.store.createRecord(modelType, defaults));
			this.set('checkIntervalLabel', 'Every Weekday');
			this.set('automation.daysOfWeek', weekdays);
			// this.set('automation.daysOfMonth', allDaysOfMonth);
			this.set('automation.interval', 1);
			// preselect the 1st and 15th as the month days
			this.set('monthDayOption1', this.monthDayOptions[0]);
			this.set('monthDayOption2', this.monthDayOptions[14]);
			// preselect days as type for 'Every Weekday'
			this.set('automation.triggerType', 'days');

			if (this.caseType) {
				this.automation.set('caseType', this.caseType);
				this.automation.set('firm', this.company.info);
			}
		} else if (this.caseType) {
			//* Only show stage options for the amount of stages on the Practice Area
			this.stages = this.caseType?.caseStatuses
				?.sortBy('number')
				.map((status) => {
					return EmberObject.create({
						value: status.number,
						checked:
							this.caseType?.automationTemplates?.statuses?.includes(
								status.number,
							) || false,
						label: status.name,
					});
				});
		}

		if (!this.currentUser.permissions.treatment_log) {
			const index = this.triggerOptions.indexOf('Treatment Reminder');
			if (index !== -1) {
				this.triggerOptions.splice(index, 1);
			}
		}

		if (!this.selectedLimitType || !this.automation.get('callLimit')) {
			//* If null set to none (index 0)
			this.set('selectedLimitType', this.limitTypeOptions[0]);
		} else if (this.automation.get('callLimit')) {
			this.set('selectedLimitType', this.limitTypeOptions[1]);
		}

		if (this.automation.get('content')) {
			this.shortcode
				.preview(
					this.automation.get('content'),
					this.automation.get('case.id'),
					this.automation.get('clientId'),
				)
				.then((res) => {
					this.set('messagePreview', res.data?.formatted_message);
				})
				.catch(() => {
					this.set(
						'messagePreview',
						`<i class="red-text">An error has occurred.</i>`,
					);
				});
		}

		if (!this.isNew && this.get('automation.triggerType') === 'custom') {
			this.mapCustomRecurrenceData();
		}

		this.set(
			'npsOptions',
			[
				{
					value: 0,
					checked: false,
				},
				{
					value: 1,
					checked: false,
				},
				{
					value: 2,
					checked: false,
				},
				{
					value: 3,
					checked: false,
				},
				{
					value: 4,
					checked: false,
				},
				{
					value: 5,
					checked: false,
				},
				{
					value: 6,
					checked: false,
				},
				{
					value: 7,
					checked: false,
				},
				{
					value: 8,
					checked: false,
				},
				{
					value: 9,
					checked: false,
				},
				{
					value: 10,
					checked: false,
				},
			].map((nps) => {
				nps.checked = this.automation.get('npsScores')?.includes(nps.value);
				return nps;
			}),
		);
	}

	messagePreview = null;

	@computed(
		'isChecklistTemplateType',
		'automation.automationType',
		'stages.@each.checked',
		function () {
			const singleStageAutomations = [
				'delayed_status_message',
				'status_message',
			];
			if (
				!this.isChecklistTemplateType &&
				!singleStageAutomations.includes(this.automation.get('automationType'))
			)
				return false;

			for (let i = 0; i < this.stages.length; i++) {
				const stage = this.stages[i];
				if (stage.checked) {
					return true;
				}
			}
			return false;
		},
	)
	disableStages;

	/**
	 * Maps the custom recurrence data from the automation model to a structured format.
	 * This includes mapping days of the week, months of the year, and selected month and day.
	 */
	mapCustomRecurrenceData() {
		// Map daysOfWeek to weekDayOptions with appropriate labels and checked status
		const weekDayOptions =
			this.get('automation.daysOfWeek')?.map((day) => {
				return {
					label: automationOptions.weekDayOptions.find(
						(option) => option.value === day,
					)?.label, // Find the label corresponding to the day value
					checked: true,
				};
			}) || [];

		// Map monthsOfYear to monthOptions with appropriate labels
		const monthOptions =
			this.get('automation.monthsOfYear')?.map((month) => {
				return automationOptions.monthOptions.find(
					(option) => option.value === month,
				);
			}) || [];

		// Find the primary month day option based on triggerDays
		const primaryMonthDayOption = this.monthDayOptions.find(
			(option) => option.value === this.get('automation.triggerDays')[0],
		);

		// Select the first month from monthsOfYear or use the selectedMonth from automation
		const selectedMonth =
			this.get('automation.monthsOfYear')?.map((month) => {
				return monthOptions.find((option) => option.value === month);
			})[0] || this.get('automation.selectedMonth');

		// Select the first day from daysOfMonth or use the selectedMonth from automation (seems like a typo, should be selectedDay)
		const selectedDay =
			this.get('automation.daysOfMonth')?.map((day) => {
				return automationOptions.monthDayOptions.find(
					(option) => option.value === day,
				);
			})[0] || this.get('automation.selectedDay');

		// Store the mapped custom recurrence data
		this.savedCustomRecurrenceData = {
			frequencyAmount: this.get('automation.frequencyAmount'),
			frequencyType: this.get('automation.frequencyType'),
			weekDayOptions: weekDayOptions.length
				? weekDayOptions
				: this.get('automation.weekDayOptions')?.map((day) => {
						return {
							label: day,
							checked: true,
						};
				  }),
			primaryMonthDayOption,
			selectedMonth,
			selectedDay,
		};
	}

	willRender() {
		super.willRender(...arguments);
		// Parse the existing automation to populate inputs
		if (!this.isNew && !this.isLoaded) {
			switch (this.get('automation.triggerType')) {
				case 'days':
					this.set('checkIntervalLabel', 'Every Weekday');
					this.set('monthDayOption1', this.monthDayOptions[0]);
					this.set('monthDayOption2', this.monthDayOptions[14]);
					break;
				case 'weeks':
					this.set('checkIntervalLabel', 'Weekly');
					this.get('automation.daysOfWeek').forEach((check) => {
						this.weekDayOptions[check].set('checked', true);
					});
					this.set('monthDayOption1', this.monthDayOptions[0]);
					this.set('monthDayOption2', this.monthDayOptions[14]);
					break;
				case 'months':
					this.set(
						'monthDayOption1',
						this.monthDayOptions[this.get('automation.triggerDays')[0] - 1],
					);
					if (this.get('automation.triggerDays').length > 1) {
						this.set('checkIntervalLabel', 'Twice Monthly');
						this.set(
							'monthDayOption2',
							this.monthDayOptions[this.get('automation.triggerDays')[1] - 1],
						);
					} else {
						this.set('checkIntervalLabel', 'Monthly');
					}
					break;
				case 'custom':
					this.set('checkIntervalLabel', 'Custom');
					this.mapCustomRecurrenceData();
					this.set('savedCustomRecurrenceData', this.savedCustomRecurrenceData);
					break;
			}

			const triggerTime =
				Number(this.get('automation.triggerTime')) < 10
					? `0${Number(this.get('automation.triggerTime'))}`
					: Number(this.get('automation.triggerTime'))
					? Number(this.get('automation.triggerTime'))
					: 12;
			const triggerMoment = moment(
				moment().format('YYYY-MM-DD') + 'T' + triggerTime + ':00',
			);
			this.set('hourString', triggerMoment.format('h:mm'));
			this.set('amOrPm', triggerMoment.format('A'));
			this.set('isLoaded', true);

			if (this.caseType || this.get('automation.statuses.length')) {
				const statuses = this.get('automation.statuses') || [];
				this.stages.forEach((stageOption) => {
					stageOption.set(
						'checked',
						statuses?.includes(stageOption.value) || false,
					);
				});
			}
		} else if (this.isNew) {
			if (!this.amOrPm) {
				this.set('amOrPm', 'PM');
			}

			if (!this.hourString) {
				this.set('hourString', '12:00');
			}
		}
	}

	dobCheckCounter = 0;
	didRender() {
		super.didRender(...arguments);

		//* Using a counter so it only fires once
		if (
			!this.dobCheckCounter &&
			this?.automation?.checkForDoB &&
			typeof this.automation.checkForDoB == 'function'
		) {
			this.automation.checkForDoB();
			this.dobCheckCounter = 1;
		}
	}

	willDestroy() {
		super.willDestroy(...arguments);
		this.rawAutomation?.rollbackAttributes();
	}

	classNames = ['automation-item'];
	classNameBindings = [
		'isEditing:is-editing',
		'automation.errors.length:error',
	];
	automationEnabled = true;
	@tracked _isEditing = false;
	@tracked isDeleting = false;
	@tracked checkIntervalLabel = '';
	@tracked monthDayOption1 = null;
	@tracked monthDayOption2 = null;
	@tracked hourString;
	@tracked amOrPm;
	amOrPmOptions = ['AM', 'PM'];
	@tracked isLoaded = false;

	@tracked stages;

	@computed(
		'automation.{automationType,daysOfWeek}',
		'weekDayOptions',
		function () {
			const mappedSelectedWeekdayOptions = this.weekDayOptions
				.map((option) => {
					//* Map through options to get just the labels in an array for only the matching selected dayOptions, returns undefined if not selected
					if (this.automation.get('daysOfWeek')?.includes(option.value)) {
						return option.label;
					} else {
						return false;
					}
				})
				.filter((a) => {
					return a;
				});
			const mappedSelectedWeekdayOptionsText = mappedSelectedWeekdayOptions
				.map((dayOfWeek, i, arr) => {
					//* Filter out undefined values and map new strings to have grammatically correct conjunctions
					if (i < arr.length - 1 && arr.length > 2) {
						return `${dayOfWeek}${
							this.automation.get('automationType') == 'no_case_changes'
								? ''
								: 's'
						},`;
					} else if (i == arr.length - 1 && arr.length >= 2) {
						return `${
							this.automation.get('automationType') == 'no_case_changes'
								? 'or'
								: 'and'
						} ${dayOfWeek}${
							this.automation.get('automationType') == 'no_case_changes'
								? ''
								: 's'
						}`;
					} else if (i <= arr.length - 1 && arr.length <= 2) {
						return dayOfWeek;
					}
				})
				.join(' '); //* Join strings in the final array using spaces
			return mappedSelectedWeekdayOptionsText;
		},
	)
	mappedSelectedWeekdayOptionsText;

	@computed(
		'automation.{automationType,daysOfWeek,daysAfterEvent}',
		'monthDayOption1',
		'monthDayOption1.label',
		'monthDayOption2',
		'monthDayOption2.label',
		'hourString',
		'checkIntervalLabel',
		'amOrPm',
		'company',
		'company.info.timezone',
		'weekDayOptions',
		function () {
			const type = this.automation.get('automationType');

			const checkIntervalLabel = this.checkIntervalLabel;

			//* Adding the Firm's set Timezone to the Time string so they know that is when it will send vs their current timezone if traveling, or that it isn't based on the client's timezone either.
			const firmTimezone = this.company.info.timezone;
			const timeText = `${this.hourString} ${this.amOrPm} ${firmTimezone}`;

			const mappedSelectedWeekdayOptions = this.weekDayOptions
				.map((option) => {
					//* Map through options to get just the labels in an array for only the matching selected dayOptions, returns undefined if not selected
					if (this.automation.get('daysOfWeek')?.includes(option.value)) {
						return option.label;
					} else {
						return false;
					}
				})
				.filter((a) => {
					return a;
				});

			if (type === 'no_case_changes') {
				const caseLabel = dynamicCaseLabel([this.company]);
				const daysAfterEvent = this.automation.get('daysAfterEvent');
				const daysAfterEventDayOrDays =
					this.automation.get('daysAfterEvent') > 1 ? 's' : '';
				const daysAfterEventText = daysAfterEvent
					? ` after ${daysAfterEvent} day${daysAfterEventDayOrDays}`
					: '';

				const mappedSelectedWeekdayOptionsText = mappedSelectedWeekdayOptions
					.map((dayOfWeek, i, arr) => {
						//* Filter out undefined values and map new strings to have grammatically correct conjunctions
						if (i < arr.length - 1 && arr.length > 2) {
							return `${dayOfWeek},`;
						} else if (i == arr.length - 1 && arr.length >= 2) {
							return `or ${dayOfWeek}`;
						} else if (i <= arr.length - 1 && arr.length <= 2) {
							return dayOfWeek;
						}
					})
					.join(' '); //* Join strings in the final array using spaces

				const intervalLabelText =
					checkIntervalLabel == 'Every Weekday'
						? `on next weekday` //* Saying "Every Weekday" here makes it sound like it will keep sending the message EVERY weekday once the daysAfterEvent time has passed, I know that the first automated message sent resets the event timer, but it can still cause a lot of doubt and lack of faith in our automations
						: checkIntervalLabel == 'Weekly'
						? `on the following ${mappedSelectedWeekdayOptionsText}`
						: checkIntervalLabel == 'Monthly'
						? `on the ${this.monthDayOption1?.label} of the month`
						: checkIntervalLabel == 'Twice Monthly'
						? `on the ${this.monthDayOption1?.label} or ${this.monthDayOption2?.label} of the month`
						: checkIntervalLabel;

				return `If there are no changes to the ${caseLabel}${daysAfterEventText} a message will be sent, ${intervalLabelText}, to the client at ${timeText}`;
			} else {
				const mappedSelectedWeekdayOptionsText = mappedSelectedWeekdayOptions
					.map((dayOfWeek, i, arr) => {
						//* Filter out undefined values and map new strings to have grammatically correct conjunctions
						if (i < arr.length - 1 && arr.length > 2) {
							return `${dayOfWeek}s,`;
						} else if (i == arr.length - 1 && arr.length >= 2) {
							return `and ${dayOfWeek}s`;
						} else if (i <= arr.length - 1 && arr.length <= 2) {
							return `${dayOfWeek}s`;
						}
					})
					.join(' '); //* Join strings in the final array using spaces
				const intervalLabelText =
					checkIntervalLabel == 'Weekly'
						? `On ${mappedSelectedWeekdayOptionsText}`
						: checkIntervalLabel == 'Monthly'
						? `On the ${this.monthDayOption1?.label}`
						: checkIntervalLabel == 'Twice Monthly'
						? `On the ${this.monthDayOption1?.label} and ${this.monthDayOption2?.label}`
						: checkIntervalLabel;
				return `${intervalLabelText}, a message will be sent to the client at ${timeText}`;
			}
		},
	)
	summaryMessage;

	@tracked showContentPreview = false;

	get canDelete() {
		//* Retroactive only applies to PAA which have a caseType relationship and also not to checklist PAAs
		if (!this.caseType || this.isChecklistTemplateType) return true;

		const retroactiveDelete = this.retroactiveDelete;
		const retroactiveKeep = this.retroactiveKeep;

		//* Should not be allowed to choose both
		const onlyOneOptionSelected = !(retroactiveDelete && retroactiveKeep);

		//* Has to at least choose one
		const atLeastOneOptionSelected = retroactiveDelete || retroactiveKeep;

		//* Validate
		const ableToDelete = onlyOneOptionSelected && atLeastOneOptionSelected;

		return ableToDelete;
	}

	@tracked _retroactiveDeleteState = false;

	get retroactiveDelete() {
		const retroactiveDeleteState = this._retroactiveDeleteState;
		return retroactiveDeleteState;
	}

	set retroactiveDelete(value) {
		this.set('_retroactiveDeleteState', value);
		if (value === true) {
			//* Must only let one be active, but both can be inactive.
			this.set('_retroactiveKeepState', false);
			//* Set on the raw data model since delete doesn't get called on the changeset data model
			this.set('rawAutomation.retroactive', true);
		}
	}

	@tracked _retroactiveKeepState = false;

	get retroactiveKeep() {
		return this._retroactiveKeepState;
	}

	set retroactiveKeep(value) {
		this.set('_retroactiveKeepState', value);
		if (value === true) {
			//* Must only let one be active, but both can be inactive.
			this.set('_retroactiveDeleteState', false);
			//* Set on the raw data model since delete doesn't get called on the changeset data model
			this.set('rawAutomation.retroactive', false);
		}
	}

	@action
	toggleShowContentPreview() {
		this.toggleProperty('showContentPreview');
	}

	@action
	hideContentPreview() {
		this.set('showContentPreview', false);
	}

	@action
	toggleAutomation() {
		this.toggleProperty('automationEnabled');
	}

	@action
	selectMonthDayOption1(option) {
		this.set('monthDayOption1', option);
	}

	@action
	selectMonthDayOption2(option) {
		this.set('monthDayOption2', option);
	}

	@action
	selectHourString(hour) {
		this.set('hourString', hour);
	}

	@action
	onAmpmChangeHandler(amOrPm) {
		this.set('amOrPm', amOrPm);
		this.set('dateChanged', true);
	}

	@action
	setEditModeTrue() {
		this.set('isEditing', true);
		if (this.toggleEditMode && typeof this.toggleEditMode == 'function') {
			this.toggleEditMode(true);
		}

		//* Add dummy content so the validations pass for this automationType, it will be striped away during the save/update process
		if (this.isChecklistTemplateType) {
			this.automation.content = ' ';
		}

		this.set('automation', new Changeset(this.automation));
	}

	@action
	setEditModeFalse() {
		console.log('Canceling', this.rawAutomation);
		this.set('isEditing', false);
		if (this.toggleEditMode && typeof this.toggleEditMode == 'function') {
			this.toggleEditMode(false);
		}
		this.set('errors', null);
		this.set('isLoaded', false);
		this.rawAutomation?.rollbackAttributes();
		console.log('rollbackAttributes', this.rawAutomation);
		if (this.rawAutomation) {
			this.set('automation', new Changeset(this.rawAutomation));
		}
	}

	@action
	setIsDeletingTrue() {
		this.set('isDeleting', true);
	}

	@action
	setIsDeletingFalse() {
		this.set('isDeleting', false);
	}

	@action
	deleteAutomationItem() {
		const automation = this.rawAutomation;
		const result = defer();
		this.deleteAutomation(automation, result);
		this.set('errors', null);

		result.promise
			.then(() => {
				this.notifications.success('Automation Deleted Successfully!', {
					canClose: true,
					autoClear: true,
					clearDuration: 3000,
				});
				if (this.toggleEditMode && typeof this.toggleEditMode == 'function') {
					this.toggleEditMode(false);
				}
			})
			.catch((errors) => {
				this.set('errors', Errors.mapResponseErrors(errors));
			});

		return result.promise;
	}

	@action
	setSelectedTrigger(trigger) {
		let type;

		//* Start nulling properties and letting the switch set only the required values
		this.set('automation.dateType', null);
		this.set('checkIntervalLabel', '');

		switch (trigger) {
			case `No ${capitalize(dynamicCaseLabel([this.company]))} Changes`:
				type = 'no_case_changes';
				this.set(
					'automation.content',
					`We are diligently working on your ${dynamicCaseLabel([
						this.company,
					])}. There are no updates to report at this time. If you have any additional questions, please message us on Case Status.`,
				);
				break;
			case 'Recurring Message':
				type = 'recurring_message';
				this.set(
					'automation.content',
					'Please remember to pay your invoice this month.',
				);
				break;
			case 'Treatment Reminder':
				type = 'treatment_reminder';
				this.set('automation.content', 'Did you get treatment this week?');
				break;
			case 'Add Checklist Template':
				type = 'add_checklist_template';
				//* Validations require content being present, so we add dummy content
				this.set('automation.content', 'checklist');
				this.resetStages();
				break;
			case 'Add Internal Checklist Template':
				type = 'add_checklist_template_chat';
				//* Validations require content being present, so we add dummy content
				this.set('automation.content', 'checklist');
				this.resetStages();
				break;
			case 'Date Based Message':
				type = 'date_based';
				this.set(
					'automation.content',
					`Happy Birthday {{client_name}} from everyone at {{firm_name}} 🎉🎂`,
				);
				this.set('automation.dateType', 'client_birthday');
				break;
			case 'Single Message':
				type = 'status_message';
				this.resetStages();
				this.automation.set('callLimit', 1);
				this.automation.set('holdUntilActivation', true);
				this.automation.set('runClosed', false);
				this.automation.set('runHold', false);
				this.automation.set('triggerDays', null);
				this.automation.set('triggerTime', null);
				this.automation.set('daysOfWeek', null);
				this.automation.set('daysOfMonth', null);
				this.automation.set('hourOfDay', null);
				this.automation.set('daysAfterEvent', null);
				this.automation.set('content', null);
				this.automation.set('triggerType', null);
				break;
			case 'Single Delayed Message':
				type = 'delayed_status_message';
				this.resetStages();
				this.automation.set('callLimit', 1);
				this.automation.set('holdUntilActivation', true);
				this.automation.set('runClosed', false);
				this.automation.set('runHold', false);
				this.automation.set('triggerDays', null);
				this.automation.set('triggerTime', null);
				this.automation.set('daysOfWeek', null);
				this.automation.set('daysOfMonth', null);
				this.automation.set('hourOfDay', null);
				this.automation.set('content', null);
				this.automation.set('triggerType', null);
				break;
			case 'Single Message based on NPS':
				type = 'nps_message';
				this.resetStages();
				this.automation.set('holdUntilActivation', true);
				this.automation.set('runClosed', false);
				this.automation.set('runHold', false);
				this.automation.set('triggerDays', null);
				this.automation.set('triggerTime', null);
				this.automation.set('daysOfWeek', null);
				this.automation.set('daysOfMonth', null);
				this.automation.set('hourOfDay', null);
				this.automation.set('daysAfterEvent', null);
				this.automation.set('content', null);
				this.automation.set('triggerType', null);
				break;
			default:
				type = 'nothing';
				break;
		}

		this.set('automation.automationType', type);
		this.set('automation.checklistTemplate', null);
		this.set(
			'automation.stageValidationRequired',
			typesRequiringStageValidation.includes(type),
		);
	}

	@action
	setSelectedTriggerType(check) {
		let type;
		this.set('checkIntervalLabel', check);

		switch (check) {
			case 'Every Weekday':
				type = 'days';
				this.set('automation.daysOfWeek', [0, 1, 2, 3, 4]);
				break;
			case 'Weekly':
				type = 'weeks';
				break;
			case 'Monthly':
				type = 'months';
				this.set('automation.daysOfWeek', [0, 1, 2, 3, 4, 5, 6]);
				break;
			case 'Twice Monthly':
				type = 'months';
				this.set('automation.daysOfWeek', [0, 1, 2, 3, 4, 5, 6]);
				break;
			case 'Custom':
				type = 'custom';
				this.toggleFrequencyIntervalModal();
				break;
		}

		this.set('automation.triggerType', type);
	}

	@action
	handleStageSelect() {
		//* this should only run for automationTemplates, which means a caseType must be present
		if (this.caseType) {
			//* Run this in the next runloop so the values have time to be set, otherwise this seems to run first and ends up one value behind.
			next(() => {
				const statuses = this.stages.filterBy('checked').map((status) => {
					return status.value;
				});
				this.automation.set('statuses', statuses);
			});
		}
	}

	@action
	handleSelectDaysOfWeek() {
		if (this.get('automation.triggerType') === 'weeks') {
			next(() => {
				this.set('automation.daysOfWeek', A([]));
				this.weekDayOptions.forEach((option) => {
					if (option.checked) {
						this.get('automation.daysOfWeek').push(option.value);
					}
				});
			});
		}
	}

	@action
	saveAutomationItem() {
		const result = defer();

		// filter the days of the week into triggerDays

		//* Default to 14 if NaN
		if (isNaN(Number(this.automation.get('daysAfterEvent')))) {
			this.automation.set('daysAfterEvent', 14);
		}

		// add the days of the month into triggerDays
		if (this.get('automation.triggerType') === 'months') {
			this.set('automation.triggerDays', A([]));
			this.get('automation.triggerDays').push(this.monthDayOption1.value);
			if (this.monthDayOption2 && this.checkIntervalLabel === 'Twice Monthly') {
				this.get('automation.triggerDays').push(this.monthDayOption2.value);
			}
		}

		const timeString = `${this.hourString} ${this.amOrPm}`;
		const tz = this.get('currentUser.user.firm.timezone') || 'America/New_York';
		const triggerTime = moment
			.tz(
				moment().tz(tz).format('YYYY-MM-DD') + ' ' + timeString,
				'YYYY-MM-DD h:mm a',
				tz,
			)
			.format('HH');

		this.set('automation.triggerTime', triggerTime);

		if (
			this.isAutomationValid &&
			(this.caseType ? this.areSelectedStagesConsecutive : true)
		) {
			const automation = this.automation;

			if (this.checkIntervalLabel === 'Custom') {
				const {
					frequencyAmount,
					frequencyType,
					weekDayOptions,
					primaryMonthDayOption,
					selectedMonth,
					selectedDay,
				} = this.savedCustomRecurrenceData;

				let daysOfWeek = [];
				let daysOfMonth = [];
				let monthsOfYear = [];

				if (!frequencyAmount || !frequencyType) {
					this.set(
						'validationError',
						'Frequency amount and type are required.',
					);
					return result.reject();
				}

				switch (frequencyType) {
					case 'days':
						// Defaults
						daysOfMonth = CustomFrequencyDefaults.daysOfMonth;
						daysOfWeek = CustomFrequencyDefaults.daysOfWeek;
						monthsOfYear = CustomFrequencyDefaults.monthsOfYear;
						break;
					case 'weeks':
						daysOfMonth = CustomFrequencyDefaults.daysOfMonth;
						monthsOfYear = CustomFrequencyDefaults.monthsOfYear;

						daysOfWeek = weekDayOptions
							.filter((day) => day.checked)
							.map((day) => day.value);
						break;
					case 'months':
					case 'years':
						// Defaults
						daysOfWeek = CustomFrequencyDefaults.daysOfWeek;
						if (frequencyType === 'months') {
							daysOfMonth = [primaryMonthDayOption.value];
							// Defaults
							monthsOfYear = CustomFrequencyDefaults.monthsOfYear;
						} else if (frequencyType === 'years') {
							daysOfMonth = [selectedDay.value];
							monthsOfYear = [selectedMonth.value];
						}
						break;
				}

				automation.setProperties({
					frequencyAmount,
					frequencyType,
					daysOfWeek,
					daysOfMonth,
					monthsOfYear,
				});
			} else {
				/*
				 * Since automation-template.js modal checks if these frequency values are defined
				 * to select triggerType on init render of this component, we want to default frequency
				 * values to null since this.checkIntervalLabel != 'Custom'
				 */
				this.set('automation.frequencyAmount', null);
				this.set('automation.frequencyType', null);
			}

			if (this.isChecklistTemplateType) {
				automation.set('content', null); //* I guess you HAVE to use set on Changesets... Worked for 4 months and stopped, gotta love coding HA!
			}

			if (this.activeMessageTemplate) {
				if (this.caseType) {
					this.automation.set('messageTemplate', this.activeMessageTemplate);
				} else {
					this.automation.set(
						'fileId',
						this.activeMessageTemplate?.get('fileAttachment.id'),
					);
					this.automation.set(
						'messageTemplateId',
						this.activeMessageTemplate?.get('id'),
					);
				}
			}

			this.saveAction(automation, result);
			this.set('errors', null);
			this.set('showAutomationError', false);
			result.promise
				.then(() => {
					this.set('isEditing', false);
					if (this.toggleEditMode && typeof this.toggleEditMode == 'function') {
						this.toggleEditMode(false);
					}
					this.set('isNew', false);
					this.set('showRetroactiveConfirmationModal', false);
					this.notifications.success('Automation Saved Successfully!', {
						canClose: true,
						autoClear: true,
						clearDuration: 3000,
					});

					this.shortcode
						.preview(
							this.automation.get('content'),
							this.automation.get('case.id'),
							this.automation.get('clientId'),
						)
						.then((res) => {
							if (!this.isDestroyed && !this.isDestroying) {
								this.set(
									'messagePreview',
									res.data?.formatted_message,
									this.automation.get('clientId'),
								);
							}
						})
						.catch(() => {
							if (!this.isDestroyed && !this.isDestroying) {
								this.set(
									'messagePreview',
									`<i class="red-text">An error has occurred.</i>`,
								);
							}
						});
				})
				.catch((errors) => {
					this.set('automation.case', null);
					this.set('errors', Errors.mapResponseErrors(errors));
				});
		} else {
			if (this.caseType && !this.areSelectedStagesConsecutive) {
				this.set('errors', [
					'Multiple stages are only allowed if they are consecutive.',
				]);
			}
			this.set('showAutomationError', true);
			result.reject();
		}
		return result.promise;
	}

	@action
	toggleShowChecklistTemplateModal() {
		this.toggleProperty('showChecklistTemplateModal');
	}

	@action
	toggleFrequencyIntervalModal() {
		this.showFrequencyIntervalModal = !this.showFrequencyIntervalModal;
	}

	@action
	handleCustomRecurrenceData(data) {
		if (!data.frequencyAmount || !data.frequencyType) {
			this.set('validationError', 'Frequency amount and type are required.');
			this.showFrequencyIntervalModal = true; // Ensure modal stays open
			return;
		}
		this.set('validationError', null); // Clear error if data is valid
		this.savedCustomRecurrenceData = data;
		this.automation.setProperties({
			frequencyAmount: data.frequencyAmount,
			frequencyType: data.frequencyType,
			weekDayOptions: data.weekDayOptions
				.filter((day) => day.checked)
				.map((day) => day.label),
			triggerDays: [
				data.primaryMonthDayOption ? data.primaryMonthDayOption.value : null,
			].filter(Boolean),
			selectedMonth: data.selectedMonth,
			selectedDay: data.selectedDay,
		});
	}

	@action
	selectChecklistTemplate(checklistTemplate) {
		const result = defer();
		this.set('automation.checklistTemplate', checklistTemplate);
		result.resolve();
		return result.promise;
	}

	@action
	removeChecklistTemplate() {
		this.set('automation.checklistTemplate', null);
	}

	@action
	toggleRetroactiveConfirmationModal() {
		this.toggleProperty('showRetroactiveConfirmationModal');
	}

	@action
	setSelectedLimitType(value) {
		this.set('selectedLimitType', value);
	}

	@action
	handleSelectNPS() {
		next(() => {
			const npsScores = this.npsOptions.filterBy('checked').map((nps) => {
				return nps.value;
			});
			this.automation.set('npsScores', npsScores);
		});
	}
}
