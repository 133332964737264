/* eslint-disable ember/no-component-lifecycle-hooks */
/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce, next } from '@ember/runloop';
import { sort } from '@ember/object/computed';
import moment from 'moment';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import ENV from 'case-status/config/environment';
import { A } from '@ember/array';
import Errors from 'case-status/constants/errors';
import { uniq } from '@ember/object/computed';
import { defer } from 'rsvp';
import { isFunction } from 'case-status/constants/data-types';

@classic
// eslint-disable-next-line ember/require-tagless-components
export default class CaseMessageThread extends Component {
	constructor() {
		super(...arguments);
		this.newMessageFile = { filename: null, fileId: null, fd: null };
	}

	attributeBindings = ['data-test-id'];
	'data-test-id' = 'component';

	@service inViewport;
	@service customBranding;
	@service company;
	@service currentUser;
	@service store;
	@service scroller;
	@service ajax;
	@service notifications;
	@service('pubnub-service') pubnub;
	@service permissions;
	@service activityTracking;

	@tracked loaderInViewPort = false;
	@tracked _searchTerm = '';
	@tracked isLoading = true;
	@tracked meta = {
		total_pages: 0,
	};
	@tracked page = 1;
	@tracked caseMessages = A([]);
	_isWritingMessage = false;
	@tracked showMessageActionsModal = false;
	@tracked showESignUploadModal = false;
	@tracked smsErrors = null;
	@tracked lightboxImage = null;
	@tracked messageTemplateType = null;
	@tracked shouldUpdate;
	@tracked isLoadingNew = true;
	template;
	scrollableAreaOverride;
	// max number of messages to consider when determining a message eligible for recommended responses
	RECOMMENDED_RESPONSE_MAX_THREAD_SIZE = 10;

	get isWritingMessage() {
		return this._isWritingMessage;
	}

	set isWritingMessage(value) {
		//* Somehow this fixes an issue where a checkbox was triggering this to toggle
		this.set('_isWritingMessage', value);
	}

	_newMessage = '';

	get newMessage() {
		return this._newMessage;
	}

	set newMessage(value) {
		this.set('_newMessage', value);
	}

	get recommendedResponseEligibleMessage() {
		/**
		 * Since `this.caseMessages` is sorted by sentAt descending, if we
		 * see a client message before a non-automated attorney message,
		 * this message is eligible for recommended responses. However, if
		 * we see a non-automated attorney message before a client message,
		 * then there is no message eligible for recommended responses. If
		 * there is no client OR non-automated attorney message then there
		 * is no message eligible for recommended responses.
		 * Limit the search through the message thread to the first `n` messages,
		 * currently 10, to avoid having an expensive search through a large thread.
		 */
		for (let caseMessage of this.caseMessages.slice(
			0,
			this.RECOMMENDED_RESPONSE_MAX_THREAD_SIZE,
		)) {
			if (caseMessage.senderType === 'client') {
				return caseMessage;
			} else if (caseMessage.automatedMessageType === null) {
				return null;
			}
		}
		return null;
	}

	messagesSort = ['sentAt:desc'];
	@sort('caseMessages', 'messagesSort') sortedMessages;
	@uniq('sortedMessages') uniqMessages;

	get showScheduledMessagesMessage() {
		return Boolean(
			isFunction(this.showScheduledMessages) &&
				this.theCase.get('unsentScheduledMessagesCount'),
		);
	}

	get scheduledMessagesMessage() {
		if (this.showScheduledMessagesMessage) {
			return `You have ${this.theCase.get(
				'unsentScheduledMessagesCount',
			)} message${
				this.theCase.get('unsentScheduledMessagesCount') > 1 ? 's' : ''
			} scheduled. Click here to see ${
				this.theCase.get('unsentScheduledMessagesCount') > 1 ? 'them' : 'it'
			}.`;
		} else {
			return '';
		}
	}

	get allowTranslate() {
		return (
			this.permissions.get('case.allow_message_translation') &&
			this.permissions.get('case.translate_case_type') &&
			this.theCaseRaw.get('translationEnabled')
		);
	}

	get moreMessagesToLoad() {
		return this.meta.total_pages != this.page;
	}

	get filteredMessages() {
		return this.uniqMessages.filter((message) => {
			return (
				!message.get('scheduledMessageSendDate') ||
				moment(message.get('scheduledMessageSendDate')).isBefore(moment())
			);
		});
	}

	get showLoader() {
		return this.isLoading && this.loaderInViewPort;
	}

	get smsToggleDescription() {
		const caseClients = this.theCase.get('clients') || [];
		const desc = `If turned on, client${
			caseClients.length != 1 ? 's' : ''
		} will receive notifications about ${dynamicCaseLabel([
			this.company,
		])} updates and messages as text messages.`;
		return desc;
	}

	get sendAsPrimary() {
		return this.currentUser.user.sendAsPrimary;
	}

	set sendAsPrimary(value) {
		this.currentUser.user.sendAsPrimary = value;
		this.currentUser.user.save();
	}

	get searchTerm() {
		return this._searchTerm;
	}

	set searchTerm(value) {
		this._searchTerm = value;
		this.isLoading = true;
		debounce(this, this.fetchNewCaseMessages, 500);
	}

	get messageTemplates() {
		const messageTemplates = this.store.peekAll('message-template');
		if (messageTemplates.length) {
			return messageTemplates;
		} else {
			return this.store.findAll('message-template');
		}
	}

	/* Methods */
	didEnterViewPort() {
		if (this.theCase.get('messages.length')) {
			this.loaderInViewPort = true;
			if (this.page || this.meta.total_pages) {
				this.fetchMoreCaseMessages();
			}
		}
	}

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreCaseMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreCaseMessages();
			}
		}, 500);
	}

	didExitViewPort() {
		this.loaderInViewPort = false;
	}

	get isUserOnCase() {
		const theCase = this.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}

	fetchNewCaseMessages(noCache = false) {
		//* Set loading state to true
		this.isLoading = true;
		this.isLoadingNew = true;

		//* Clear the current list of case messages
		this.caseMessages.clear();

		//* Reset page number to 1
		this.page = 1;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		this.queryParams.page.size = 20;

		//* Set page number
		this.queryParams.page.number = this.page;

		//* Set the filter object's search property to equal the current searchTerm property
		this.queryParams.filter['search'] = this.searchTerm;
		if (noCache) {
			this.set('ajax.noCache', true);
		}
		//* Make query request
		return this.store
			.query('message', {
				caseId: this.theCase.get('id'),
				params: this.queryParams,
			})
			.then((res) => {
				//* Update the shouldUpdate trigger
				this.shouldUpdate = false;

				//* Update meta data
				this.meta = res.meta;

				//* Clear old messages
				this.caseMessages.clear();

				//* Push in new messages
				this.caseMessages.pushObjects(res.toArray());

				//* Set isLoadingNew to false
				this.isLoadingNew = false;

				this.set('errorFetchingMessages', false);
			})
			.catch(() => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
				});
			});
	}

	fetchMoreCaseMessages() {
		if (!this.meta.total_pages || this.page + 1 > this.meta.total_pages) return;
		//* Set loading state to true
		this.isLoading = true;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		this.queryParams.page.size = 20;

		//* Increment the page number
		this.page = this.page + 1;

		//* Set the page number on the queryParams object
		this.queryParams.page.number = this.page;

		//* Set the filter object's search property to equal the current searchTerm property
		this.queryParams.filter['search'] = this.searchTerm;

		// Since this could happen in quick succession we want newest messages to render

		//* Make query request
		return this.store
			.query('message', {
				caseId: this.theCase.get('id'),
				params: this.queryParams,
			})
			.then((res) => {
				//* Update meta data
				this.meta = res.meta;

				//* Push in new messages
				this.caseMessages.pushObjects(res.toArray());
				this.set('errorFetchingMessages', false);
			})
			.catch(() => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
				});
			});
	}

	async handleIncomingMessage(newMessage) {
		if (
			!newMessage ||
			(await newMessage.case.get('id')) != this.theCase.get('id')
		)
			return;

		//* Check to see if the message is already in the list
		const message = this.caseMessages.find((msg) => {
			return newMessage.id == msg.id;
		});

		if (message) return;

		this.caseMessages.unshiftObject(newMessage);
		return;
	}

	/* Lifecycle */
	didInsertElement() {
		super.didInsertElement(...arguments);
		this.setupInViewport();

		this.loaderInViewPort = true;

		if (this?.theCase?.get('id')) {
			this.fetchNewCaseMessages();
		}

		if (this?.shouldSendWelcomeMessage) {
			this.send('sendWelcomeMessage');
		}

		//* Setup the incoming message handler on the pubnub newMessageCallback
		this.pubnub.newMessageCallbacks.push((...args) => {
			this.handleIncomingMessage(...args);
		});
	}

	willDestroy() {
		// need to manage cache yourself if you don't use the mixin
		const loader = document.getElementById('loader');
		this.inViewport.stopWatching(loader);

		//* We must reset the newMessageCallback to null on the pubnub service
		//* since this will no longer be loaded and we want to prevent errors
		//* and prevent useless tasks from running and wasting user resources
		this.pubnub.newMessageCallback = null;

		//? Maybe unload messages from the store to save user on resources?

		super.willDestroy(...arguments);
	}

	/* Actions */
	@action
	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
			scrollableArea: this.scrollableAreaOverride,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	}

	@action
	handleRecommendedResponseCaseThread(recommendedResponse) {
		this.recommendedResponseUsed = recommendedResponse;
	}

	@action
	updateSmsSettings() {
		this.toggleProperty('theCase.wantsSms');
		this.saveCaseModel()
			.then(() => {})
			.catch(() => {
				this.toggleProperty('theCase.wantsSms');
				this.caseRollbackAttributes();
			});
	}

	@action
	decreaseNewMessagesCount() {
		const unreadCount = this.theCase.get('newMessages');
		if (unreadCount > 0) {
			this.set('theCase.newMessages', unreadCount - 1);
			//* We get a very rare edge case where somehow the localSave method stops being a function
			if (
				this.theCase.localSave &&
				typeof this.theCase.localSave == 'function'
			) {
				this.theCase.localSave();
			} else {
				this.theCase.save();
			}
		}
	}
	@action
	fileUploadCb() {
		this.fetchNewCaseMessages(true); // Gotta be a better way...
	}
	@action
	postFile(formData, result) {
		formData.append('case_id', this.theCase.get('id'));

		const url = `${ENV.host}/files`;
		const data = formData;
		const theCaseRaw = this.theCaseRaw;
		this.ajax
			.post(url, {
				processData: false,
				contentType: false,
				data: data,
			})
			.then(() => {
				theCaseRaw.reload();
				this.fetchNewCaseMessages(); // Gotta be a better way...
				return result.resolve();
			})
			.catch((response) => {
				const errors = Errors.mapResponseErrors(response);
				this.notifications.error(errors.join(', '));
				return result.reject(errors);
			});
	}

	@action
	showLightboxImage(img) {
		this.set('lightboxImage', img);
	}

	@action
	hideLightboxImage() {
		this.set('lightboxImage', null);
	}

	@action
	toggleWritingMessage() {
		this.set('isWritingMessage', !this.isWritingMessage);
		this.set('showMessageError', false);
		this.set('newMessage', '');
		this.set('messageTemplateType', null);
		this.set('newMessageTemplateId', null);
		this.set('templateUsed', null);
	}

	@action
	toggleShowMessageActionsModal() {
		this.toggleProperty('showMessageActionsModal');
	}

	@action
	toggleScheduleModal() {
		this.toggleProperty('isSchedulingMessage');
	}

	@action
	validateNewMessage(...args) {
		const {
			result,
			originalContent,
			translatedContent,
			targetLanguage,
			recommendedResponseUsed,
		} = args[0];
		const translationEdited = args[1];
		this.newMessage = `${this.newMessage}`.trim();
		if (!this.newMessage && this.isWritingMessage) {
			this.set('showMessageError', true);
			return result.reject();
		} else {
			this.send(
				'sendMessage',
				this.newMessage,
				null,
				originalContent,
				translatedContent,
				targetLanguage,
				result,
				translationEdited,
				recommendedResponseUsed,
			);
			return result.promise
				.then(() => {
					if (!this.theCase.get('activatedDate')) {
						this.set('theCaseRaw.activatedDate', new Date());
					}
					this.set('isWritingMessage', !this.isWritingMessage);
					this.set('newMessage', '');
					this.set('messageTemplateType', null);
					this.set('newMessageTemplateId', null);
					this.set('templateUsed', null);
					this.set('showMessageError', false);
				})
				.catch(() => {
					this.set('showMessageError', true);
				});
		}
	}

	@action
	templateSelected(template) {
		const templateId = template?.templateId;
		const templateType = template?.templateType;

		this.set('messageTemplateType', templateType);
		this.set('newMessageTemplateId', templateId);
		this.set('templateUsed', template);
	}

	@action
	sendMessage(
		content,
		scheduledMessageSendDate,
		originalContent,
		translatedContent,
		targetLanguage,
		result,
		translationEdited,
		recommendedResponseUsed,
	) {
		const model = this.theCaseRaw;

		const messageTemplateType =
			this.messageTemplateType || this.templateUsed?.templateType || null;
		const messageTemplateId =
			this.newMessageTemplateId || this.templateUsed?.id || null;

		const newMessage = this.store.createRecord('message', {
			case: model,
			content: translatedContent ? translatedContent : content,
			scheduledMessageSendDate,
			messageTemplateType: messageTemplateType,
			originalContent,
			translatedContent,
			translationEdited,
			targetLanguage,
			messageTemplateId: messageTemplateId,
			file: this.newMessageFile,
		});

		if (recommendedResponseUsed !== undefined) {
			newMessage.recommendedResponseUsed = recommendedResponseUsed;
		}

		newMessage
			.save()
			.then((message) => {
				//* Tracking service handles the user type check
				this.activityTracking.trackByCase(
					this.theCase.get('id'),
					'sentMessage',
				);

				if (scheduledMessageSendDate !== null) {
					this.theCase.get('scheduledMessages').pushObject(message);
				}
				this.caseMessages.unshiftObject(message);
				this.set('messageTemplateType', null);
				this.set('newMessageTemplateId', null);
				this.set('templateUsed', null);
				if (this?.shouldSendWelcomeMessage) {
					this.set('shouldSendWelcomeMessage', false);
				}

				if (
					this.clearMessageTemplateType &&
					typeof this.clearMessageTemplateType === 'function'
				) {
					this.clearMessageTemplateType();
				}

				result.resolve();
			})
			.catch((response) => {
				newMessage.rollbackAttributes();
				this.set('errors', Errors.mapResponseErrors(response));
				result.reject(this.errors);
			});
	}

	@action
	selectMessageTemplate(template) {
		this.set('messageTemplateType', template.templateType);
		this.set('newMessage', template.content);
		this.set('newMessageFile', template.get('fileAttachment'));
		this.set('newMessageTemplateId', template.id);
		document
			.getElementById('modal-add-message-template')
			.classList.add('hidden');
	}

	@action
	sendScheduledMessage(args) {
		//* Destructure the Arguments for component action flexibility
		const {
			message,
			day,
			originalContent,
			translatedContent,
			targetLanguage,
			result,
			recommendedResponseUsed,
		} = args;

		this.send(
			'sendMessage',
			message,
			day,
			originalContent,
			translatedContent,
			targetLanguage,
			result,
			null,
			recommendedResponseUsed,
		);

		result.promise.then(() => {
			this.set('isWritingMessage', !this.isWritingMessage);
			this.toggleProperty('isSchedulingMessage');
			this.set('newMessage', '');
			this.set('messageTemplateType', null);
			this.set('newMessageTemplateId', null);
			this.set('templateUsed', null);
			this.set('showMessageError', false);
			this.set(
				'theCase.unsentScheduledMessagesCount',
				this.theCase.get('unsentScheduledMessagesCount') + 1,
			);
			if (
				this.clearMessageTemplateType &&
				typeof this.clearMessageTemplateType === 'function'
			) {
				this.clearMessageTemplateType();
			}
		});
	}

	@action
	toggleESignModal() {
		this.toggleProperty('showESignUploadModal');
	}

	@action
	eSignPostCallback() {
		if (this.eSignCallback && typeof this.eSignCallback === 'function') {
			this.eSignCallback();
		}
		this.fetchNewCaseMessages();
	}

	@action
	sendWelcomeMessage() {
		const templates = this.store.peekAll('message-template');
		const inviteTemplate = templates
			?.toArray()
			.find((template) => template.templateType == 'invite');
		const welcomeMessage = inviteTemplate?.content;

		this.set('newMessage', welcomeMessage);
		//* isWritingMessage is firing renders too fast before the other data is actually being set
		next(() => {
			this.set('isWritingMessage', true);
		});
		setTimeout(() => {
			//* somehow the app/firm/case/info router is nulling this value on the controller
			this.set('messageTemplateType', 'invite');
		}, 250);
	}

	@action
	sendOwnMessage() {
		this.set('isWritingMessage', true);
		this.scroller.scrollVertical('.progress-bar');
	}

	@action
	sendReviewRequest() {
		const reviewMessage = this.company.info.reviewMessage;
		this.set('newMessage', reviewMessage);
		this.send('sendOwnMessage');
	}

	@action
	markAllMessagesRead(result = defer()) {
		const caseModel = this.theCase;
		const caseId = caseModel.get('id');
		let url = `${ENV.host}/messages/mark_as_read?case_id=${caseId}&is_read=true`;
		return this.ajax
			.post(url)
			.then(() => {
				this.store.peekAll('notification').forEach((notification) => {
					try {
						const message = this.store.peekRecord(
							'message',
							notification.messageId,
						);
						if (
							message &&
							notification.get('caseId') === caseId &&
							message.get('senderChildId') != this.currentUser.get('user.id')
						) {
							const readAt = notification.get('readAt');
							if (!readAt) {
								notification.set('readAt', new Date());
							}
						}

						if (
							this.currentUser.get('user.constructor.modelName') == 'client'
						) {
							this.activityTracking.trackByCase(
								this.theCase.get('id'),
								'readMessage',
							);
						}
					} catch (error) {
						this.set('errors', Errors.mapResponseErrors(error));
						this.notifications.clearAll();
						this.notifications.error(this.get('errors'), {
							canClose: true,
							autoClear: true,
							clearDuration: 3000,
						});
						result.reject();
					}
				});
				caseModel.set('newMessages', 0);
				result.resolve();
				caseModel.save();
			})
			.catch((response) => {
				this.set('errors', Errors.mapResponseErrors(response));
				result.reject();
			});
	}

	@action
	markAllMessagesReadForTeam(result = defer()) {
		const caseModel = this.theCase;
		const caseId = caseModel.get('id');
		let url = `${ENV.host}/messages/mark_as_read?case_id=${caseId}&read_by_all=true`;
		return this.ajax
			.post(url)
			.then(() => {
				this.store.peekAll('notification').forEach((notification) => {
					try {
						const message = this.store.peekRecord(
							'message',
							notification.messageId,
						);
						if (
							message &&
							notification.get('caseId') === caseId &&
							message.get('senderChildId') != this.currentUser.get('user.id')
						) {
							const readAt = notification.get('readAt');
							if (!readAt) {
								notification.set('readAt', new Date());
							}
						}
					} catch (error) {
						this.set('errors', Errors.mapResponseErrors(error));

						this.notifications.clearAll();
						this.notifications.error(this.get('errors'), {
							canClose: true,
							autoClear: true,
							clearDuration: 3000,
						});

						result.reject();
					}
				});
				caseModel.set('newMessages', 0);
				result.resolve();
				caseModel.save();
				this.fetchNewCaseMessages();
			})
			.catch((response) => {
				this.set('errors', Errors.mapResponseErrors(response));
				result.reject();
			});
	}

	@action trackScrolling() {
		if (this.scrollingTracked) return;
		this.scrollingTracked = true;
		this.activityTracking.trackByCase(
			this.theCase.get('id'),
			'scrolledMessages',
		);
	}
}
